import { default as _401Dh5FEQcGGLMeta } from "/builds/flightmate/dash-web/pages/401.vue?macro=true";
import { default as users6phI8tpwL8Meta } from "/builds/flightmate/dash-web/pages/admin/users.vue?macro=true";
import { default as eventstVlVnDJeGaMeta } from "/builds/flightmate/dash-web/pages/analytics/events.vue?macro=true";
import { default as funnelj8JJU6f5elMeta } from "/builds/flightmate/dash-web/pages/analytics/funnel.vue?macro=true";
import { default as page_45viewsblYnHink0zMeta } from "/builds/flightmate/dash-web/pages/analytics/page-views.vue?macro=true";
import { default as savedu8WNt5tdxAMeta } from "/builds/flightmate/dash-web/pages/analytics/saved.vue?macro=true";
import { default as split_45test2gh21e18NFMeta } from "/builds/flightmate/dash-web/pages/analytics/split-test.vue?macro=true";
import { default as analyticsjA2RZVsS2AMeta } from "/builds/flightmate/dash-web/pages/analytics.vue?macro=true";
import { default as indexeSlU6Am0Y2Meta } from "/builds/flightmate/dash-web/pages/index.vue?macro=true";
import { default as loginkJ9iI60yDuMeta } from "/builds/flightmate/dash-web/pages/login.vue?macro=true";
import { default as meUUGMUAE5EfMeta } from "/builds/flightmate/dash-web/pages/me.vue?macro=true";
import { default as settings_45mobileFfaCTKgJqKMeta } from "/builds/flightmate/dash-web/pages/settings-mobile.vue?macro=true";
import { default as usersBZGkKCfA0pMeta } from "/builds/flightmate/dash-web/pages/users.vue?macro=true";
export default [
  {
    name: "401",
    path: "/401",
    meta: _401Dh5FEQcGGLMeta || {},
    component: () => import("/builds/flightmate/dash-web/pages/401.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/builds/flightmate/dash-web/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/builds/flightmate/dash-web/pages/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "analytics-events",
    path: "events",
    component: () => import("/builds/flightmate/dash-web/pages/analytics/events.vue").then(m => m.default || m)
  },
  {
    name: "analytics-funnel",
    path: "funnel",
    component: () => import("/builds/flightmate/dash-web/pages/analytics/funnel.vue").then(m => m.default || m)
  },
  {
    name: "analytics-page-views",
    path: "page-views",
    component: () => import("/builds/flightmate/dash-web/pages/analytics/page-views.vue").then(m => m.default || m)
  },
  {
    name: "analytics-saved",
    path: "saved",
    component: () => import("/builds/flightmate/dash-web/pages/analytics/saved.vue").then(m => m.default || m)
  },
  {
    name: "analytics-split-test",
    path: "split-test",
    component: () => import("/builds/flightmate/dash-web/pages/analytics/split-test.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/flightmate/dash-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginkJ9iI60yDuMeta || {},
    component: () => import("/builds/flightmate/dash-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "me",
    path: "/me",
    component: () => import("/builds/flightmate/dash-web/pages/me.vue").then(m => m.default || m)
  },
  {
    name: "settings-mobile",
    path: "/settings-mobile",
    component: () => import("/builds/flightmate/dash-web/pages/settings-mobile.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/builds/flightmate/dash-web/pages/users.vue").then(m => m.default || m)
  }
]