import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAnalyticsEventsCard, LazyAnalyticsFunnelCard, LazyAnalyticsPageViewCard, LazyAnalyticsSplitTestCard, LazyGraphCard, LazyGraphCardNew, LazyModalDynamicCardAdd, LazyNumericalCard, LazyNumericalCardPoll, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["AnalyticsEventsCard", LazyAnalyticsEventsCard],
["AnalyticsFunnelCard", LazyAnalyticsFunnelCard],
["AnalyticsPageViewCard", LazyAnalyticsPageViewCard],
["AnalyticsSplitTestCard", LazyAnalyticsSplitTestCard],
["GraphCard", LazyGraphCard],
["GraphCardNew", LazyGraphCardNew],
["ModalDynamicCardAdd", LazyModalDynamicCardAdd],
["NumericalCard", LazyNumericalCard],
["NumericalCardPoll", LazyNumericalCardPoll],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
