import revive_payload_client_4sVQNw7RlN from "/builds/flightmate/dash-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/flightmate/dash-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/flightmate/dash-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/flightmate/dash-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/flightmate/dash-web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/flightmate/dash-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/flightmate/dash-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/flightmate/dash-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/flightmate/dash-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_Y6agwlnXZq from "/builds/flightmate/dash-web/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/builds/flightmate/dash-web/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import slideovers_X7whXyrIWR from "/builds/flightmate/dash-web/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/builds/flightmate/dash-web/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/builds/flightmate/dash-web/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/builds/flightmate/dash-web/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/builds/flightmate/dash-web/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_y9dIov5OEk from "/builds/flightmate/dash-web/node_modules/nuxt3-vuex-module/src/plugin.js";
import breakpoints_AXORAsvJyN from "/builds/flightmate/dash-web/plugins/breakpoints.js";
import connectApi_KWYfho41C4 from "/builds/flightmate/dash-web/plugins/connectApi.js";
import dateFns_lfosnPcMdm from "/builds/flightmate/dash-web/plugins/dateFns.js";
import draggable_EKFVpjGqMx from "/builds/flightmate/dash-web/plugins/draggable.js";
import nuxt_client_init_client_jOpD4TqhGF from "/builds/flightmate/dash-web/plugins/nuxt-client-init.client.js";
import vue_apexcharts_client_DomB4Mlebn from "/builds/flightmate/dash-web/plugins/vue-apexcharts.client.js";
import vue3_funnel_graph_client_tsUlNXXquR from "/builds/flightmate/dash-web/plugins/vue3-funnel-graph.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  plugin_y9dIov5OEk,
  breakpoints_AXORAsvJyN,
  connectApi_KWYfho41C4,
  dateFns_lfosnPcMdm,
  draggable_EKFVpjGqMx,
  nuxt_client_init_client_jOpD4TqhGF,
  vue_apexcharts_client_DomB4Mlebn,
  vue3_funnel_graph_client_tsUlNXXquR
]